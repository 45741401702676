import React, { Component } from 'react'
import NavBar from './NavBar'
import Grid from 'react-bootstrap/lib/Grid'
import Row from 'react-bootstrap/lib/Row'
import Col from 'react-bootstrap/lib/Col'
import Modal from 'react-bootstrap/lib/Modal'
import UserList from './UserList'
import ChatBox from './ChatBox'
import ErrorModal from './ErrorModal'
import LoadingModal from './LoadingModal'
import Button from 'react-bootstrap/lib/Button'
import 'react-chat-elements/dist/main.css'
import './style/Dashboard.css'
import '../index.css'
import jwt_decode from 'jwt-decode'
import io from 'socket.io-client'
import { fetchUsers } from '../requests'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import axios from 'axios'

import { setCurrentUser } from '../redux/user/user.actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

/**
 * Fetches socket server URL from env
 */
const SOCKET_URI = process.env.REACT_APP_SERVER_URI

/**
 * App Component
 *
 * initiaites Socket connection and handle all cases like disconnected,
 * reconnected again so that user can send messages when he is back online
 *
 * handles Error scenarios if requests from Axios fails.
 *
 */

class DashBoard extends Component {
  socket = null

  state = {
    signInModalShow: false,
    users: [], // Avaiable users for signing-in
    userChatData: [], // this contains users from which signed-in user can chat and its message data.
    user: null, // Signed-In User
    selectedUserIndex: null,
    showModal: false, //user add modal
    showChatBox: false, // For small devices only
    showChatList: true, // For small devices only
    error: false,
    errorMessage: '',
    chatList: false,
  }

  /**
   *
   * Setups Axios to monitor XHR errors.
   * Initiates and listen to socket.
   * fetches User's list from backend to populate.
   */

  componentDidMount() {
    debugger
    const token = localStorage.getItem('token')
    this.fetchContactList()
    const user = jwt_decode(token)
    const users = []
    const firstName = user.result.firstName
    const privateKey = user.result.privateKey
    users.push({ name: firstName, id: privateKey }, () => {
      fetchUsers().then((users) => this.setState({ users }))
    })
    this.initAxios()

    this.setState({ users: users[0] }, () => {
      this.initSocketConnection(users)
    })
    this.loadChat()
  }

  loadChat = () => {
    setTimeout(
      function () {
        this.setState({ userList: true })
      }.bind(this),
      2000
    )
  }

  fetchContactList = () => {
    const currentUser = localStorage.getItem('token')
    const user = jwt_decode(currentUser)
    const privateKey = user.result.privateKey
    const firstName = user.result.firstName
    const lastName = user.result.lastName
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/contact/contact-list`, {
        veroKey: privateKey,
        name: firstName + lastName,
      })
      .then((res) => {
        const contactParse = JSON.parse(res.data.data.contact)
        contactParse.forEach((contact) => this.state.userChatData.push(contact))
        console.log(this.state)
      })
      .catch(function (error) {
        console.log(error)
        alert('Error in fetcing user')
      })

    this.setState({ chatList: true })
  }

  initSocketConnection(data) {
    this.socket = io.connect(SOCKET_URI)
    this.socket.emit('sign-in', data)
    this.setupSocketListeners()
  }

  /**
   *
   * Checks if request from axios fails
   * and if it does then shows error modal.
   */
  initAxios() {
    axios.interceptors.request.use(
      (config) => {
        this.setState({ loading: true })
        return config
      },
      (error) => {
        this.setState({ loading: false })
        this.setState({
          errorMessage: `Couldn't connect to server. try refreshing the page.`,
          error: true,
        })
        return Promise.reject(error)
      }
    )
    axios.interceptors.response.use(
      (response) => {
        this.setState({ loading: false })
        return response
      },
      (error) => {
        this.setState({ loading: false })
        this.setState({
          errorMessage: `Some error occured. try after sometime`,
          error: true,
        })
        return Promise.reject(error)
      }
    )
  }

  /**
   *
   * Shows error if client gets disconnected.
   */
  onClientDisconnected() {
    NotificationManager.error(
      'Connection Lost from server please check your connection.',
      'Error!'
    )
  }

  /**
   *
   * Established new connection if reconnected.
   */
  onReconnection() {
    if (this.state.user) {
      this.socket.emit('sign-in', this.state.user)
      NotificationManager.success('Connection Established.', 'Reconnected!')
    }
  }

  /**
   *
   * Setup all listeners
   */

  setupSocketListeners() {
    this.socket.on('message', this.onMessageRecieved.bind(this))
    this.socket.on('reconnect', this.onReconnection.bind(this))
    this.socket.on('disconnect', this.onClientDisconnected.bind(this))
  }

  /**
   *
   * @param {MessageRecievedFromSocket} message
   *
   * Triggered when message is received.
   * It can be a message from user himself but on different session (Tab).
   * so it decides which is the position of the message "right" or "left".
   *
   * increments unread count and appends in the messages array to maintain Chat History
   */

  onMessageRecieved(message) {
    let userChatData = this.state.userChatData
    let messageData = message.message
    let targetId

    if (message.from === this.state.users.id) {
      messageData.position = 'right'
      targetId = message.to
    } else {
      messageData.position = 'left'
      targetId = message.from
    }
    let targetIndex = userChatData.findIndex((u) => u.veroKey === targetId)
    if (!userChatData[targetIndex].messages) {
      userChatData[targetIndex].messages = []
    }
    if (targetIndex !== this.state.selectedUserIndex) {
      if (!userChatData[targetIndex].unread) {
        userChatData[targetIndex].unread = 0
      }
      userChatData[targetIndex].unread++
    }
    userChatData[targetIndex].messages.push(messageData)
    this.setState({ userChatData })
  }

  /**
   *
   * @param {User} e
   *
   * called when user clicks to sign-in
   */
  onUserClicked(e) {
    let user = e.user
    this.socket.emit('sign-in', user)
    let userChatData = this.state.users.filter((u) => u.id !== user.id)
    this.setState({ user, signInModalShow: false, userChatData })
  }

  /**
   *
   * @param {ChatItem} e
   *
   * handles if user clickes on ChatItem on left.
   */
  onChatClicked(e) {
    this.toggleViews()
    let users = this.state.userChatData
    for (let index = 0; index < users.length; index++) {
      if (users[index].veroKey === e.user.veroKey) {
        users[index].unread = 0
        this.setState({ selectedUserIndex: index, userChatData: users })
        return
      }
    }
  }

  /**
   *
   * @param {messageText} text
   *
   * creates message in a format in which messageList can render.
   * position is purposely omitted and will be appended when message is received.
   */
  createMessage(text) {
    let message = {
      to: this.state.userChatData[this.state.selectedUserIndex].veroKey,
      message: {
        type: 'text',
        text: text,
        date: +new Date(),
        className: 'message',
      },
      from: this.state.users.id,
    }
    this.socket.emit('message', message)
  }

  handleModal = () => {
    this.setState({ showModal: true })
  }

  handleClose = () => {
    this.setState({ showModal: false })
  }

  handleAddContact = () => {
    debugger
    const contactveroKey = this.state.users.id
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/contact/add-contact`, {
        contactveroKey: JSON.parse(this.state.veroKey),
        veroKey: JSON.parse(contactveroKey),
        name: this.state.name,
        profileImage: null,
        blocked: false,
        Relation: '',
        contactStatus: true,
      })
      .then((res) => {
        debugger
        this.setState({ showModal: false })
        window.location.reload(false)
        // this.fetchContactList()
      })
      .catch(function (error) {
        console.log(error)
        alert('Error in fetcing user')
      })
  }

  /**
   * Toggles views from 'ChatList' to 'ChatBox'
   *
   * only on Phone
   */
  toggleViews() {
    this.setState({
      showChatBox: !this.state.showChatBox,
      showChatList: !this.state.showChatList,
    })
  }

  render() {
    let chatBoxProps = this.state.showChatBox
      ? {
          xs: 12,
          sm: 12,
        }
      : {
          xsHidden: true,
          smHidden: true,
        }

    let chatListProps = this.state.showChatList
      ? {
          xs: 12,
          sm: 12,
        }
      : {
          xsHidden: true,
          smHidden: true,
        }

    return (
      <div>
        <NavBar signedInUser={this.state.user} />
        <button
          className="primary-button add-contact "
          onClick={() => this.handleModal()}
        >
          Add Contact
        </button>

        <Modal show={this.state.showModal} onHide={() => this.handleClose()}>
          <Modal.Header closeButton>
            <Modal.Title>Add Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="add-contact-modal text-center ">
              <input
                placeholder="Name"
                value={this.state.name}
                onChange={(e) => {
                  this.setState({ name: e.target.value })
                }}
              />
              <br />
              <input
                placeholder="VeroKey"
                value={this.state.veroKey}
                onChange={(e) => {
                  this.setState({ veroKey: e.target.value })
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleClose()}>
              Close
            </Button>
            <Button variant="primary" onClick={() => this.handleAddContact()}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Grid>
          <Row className="show-grid">
            <Col {...chatListProps} md={4}>
              {this.state.userList ? (
                <UserList
                  userData={this.state.userChatData}
                  onChatClicked={this.onChatClicked.bind(this)}
                />
              ) : (
                <>
                  <h3>Loading ...</h3>
                </>
              )}
            </Col>
            <Col {...chatBoxProps} md={8}>
              <ChatBox
                signedInUser={this.state.user}
                onSendClicked={this.createMessage.bind(this)}
                onBackPressed={this.toggleViews.bind(this)}
                targetUser={
                  this.state.userChatData[this.state.selectedUserIndex]
                }
              />
            </Col>
          </Row>
        </Grid>
        <Modal show={this.state.signInModalShow}>
          <Modal.Header>
            <Modal.Title>Sign In</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <UserList
              userData={this.state.users}
              onUserClicked={this.onUserClicked.bind(this)}
              showSignInList
            />
          </Modal.Body>
        </Modal>
        <ErrorModal
          show={this.state.error}
          errorMessage={this.state.errorMessage}
        />
        <LoadingModal show={this.state.loading} />
        <NotificationContainer />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user, isLoggedIn) =>
    dispatch(setCurrentUser(user, isLoggedIn)),
})

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
  isLoggedIn: user.isLoggedIn,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DashBoard))
