import React from "react";
import "./Header.css";
function Header() {
  return (
    <div
    >
      <div className="header">
      <img
                width="10px"
                src={require("./logo_vero_hive.png")}
                alt="MegaHoot Mobile App"
              />
    
    
      <h4>MegaHoot Mobile App</h4>
      </div>
     
    </div>
  );
}

export default Header;
