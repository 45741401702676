import React, { Component } from "react";
import axios from "axios";
import "./style/HomePage.css";
import { setCurrentUser } from "../redux/user/user.actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "../App.css";
import Header from "./Header.js";
import "./Header.css";
import mega from './mega.m4v'

class HomePage extends Component {
  state = {
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    loading: false,
    userExist: true,
  };


  loaderActive = () => {
    this.setState({ loading: true });
  };

  loaderDeactive = () => {
    this.setState({ loading: false });
  };

  handleLogIn = () => {
    //user can be moved to dashboard if user is loggedIn
    // this.loaderActive()
    const { setCurrentUser } = this.props;
    setCurrentUser(null, false);
    localStorage.removeItem("token");
    const { email, password } = this.state;
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/users/login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        const data = res.data;
        // Cookies.set('user', user.payload, { expires: 7 }, { path: '/*' });
        if (data.success) {
          const isLoggedIn = true;
          setCurrentUser(data.token, isLoggedIn);
          localStorage.setItem("token", JSON.stringify(data.token));
          // remove it while refactoring use asncy and await
          if (this.props.currentUser) {
            setTimeout(() => {
              this.props.history.push("/dashboard");
            }, 3000);
          }
        } else {
          alert("Invalid Username and Password");
          this.setState({
            email: "",
            password: "",
          });
          // this.loaderDeactive()
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Invalid User Password");
      });
  };

  handleSignUp = () => {
    const { username, firstName, lastName, email, confirmPassword, password } =
      this.state;
    if (confirmPassword !== password) {
      alert("Password Don't Match !");
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/users/`, {
          firstName: firstName,
          lastName: lastName,
          username: username,
          email: email,
          age: 1,
          termsandconditions: 1,
          password: password,
        })
        .then((res) => {
          const data = res.data;
          if (!data.success) {
            alert(`${data.message}`);
            this.setState({ userExist: true });
          } else {
            this.setState({ userExist: true });
          }
        })
        .catch(function (error) {
          console.log(error);
          alert("Invalid User Password");
        });
    }
  };

  componentDidMount=()=>{
  var video=  document.getElementById('video');
  video.onloadedmetadata=()=>{
video.play()
  };
  setTimeout(() => {
    video.play()
  
  }, 5000);
   

  }
  render() {
    return (
      // <div className="home-screen">
      //   <Header></Header>

      //   <div className="login-form text-center">
      //     {this.state.userExist ? (
      //       <>
      //         <img
      //           width={100}
      //           src={require("./logo_vero_hive.png")}
      //           alt="MegaHoot Messagner"
      //         />
      //         <h2>LogIn</h2>
      //         <label>
      //           <h4>Email:</h4>
      //           <div id="float-label">
      //             <input
      //               className="User"
      //               placeholder="Enter your username "
      //               type="email"
      //               name="name"
      //               value={this.state.email}
      //               onChange={(e) => this.setState({ email: e.target.value })}
      //             />
      //           </div>
      //         </label>
      //         <label>
      //           <h4>Password:</h4>
      //           <div id="float-stand">
      //             <input
      //               classname="pwd"
      //               placeholder="Enter your password"
      //               type="password"
      //               name="name"
      //               value={this.state.password}
      //               onChange={(e) =>
      //                 this.setState({ password: e.target.value })
      //               }
      //             />
      //           </div>
      //         </label>

      //         {this.state.loading ? (
      //           <div className="loader">
      //             {/* <img src={require('./spinner/loader.gif')} alt="loader" /> */}
      //             null
      //           </div>
      //         ) : (
      //           <button className="primary-button" onClick={this.handleLogIn}>
      //             {" "}
      //             LogIn{" "}
      //           </button>
      //         )}
      //         <span>
      //           Don't have an account ?{" "}
      //           <button
      //             className="link-button"
      //             onClick={() => this.setState({ userExist: false })}
      //           >
      //             SignUp
      //           </button>
      //         </span>
      //       </>
          
          
      //     ) : (
      //       <>
      //         <h2>SignUp</h2>

      //         <label>
      //           <h4>Username:</h4>

      //           <input
      //             placeholder="jhonDoe"
      //             type="text"
      //             name="name"
      //             value={this.state.username}
      //             onChange={(e) => this.setState({ username: e.target.value })}
      //           />
      //         </label>
      //         <label>
      //           <h4>First Name:</h4>

      //           <input
      //             placeholder="Jhon"
      //             type="text"
      //             name="name"
      //             value={this.state.firstName}
      //             onChange={(e) => this.setState({ firstName: e.target.value })}
      //           />
      //         </label>
      //         <label>
      //           <h4>Last Name:</h4>

      //           <input
      //             placeholder="Doe"
      //             type="text"
      //             name="name"
      //             value={this.state.lastName}
      //             onChange={(e) => this.setState({ lastName: e.target.value })}
      //           />
      //         </label>
      //         <label>
      //           <h4>Email:</h4>

      //           <input
      //             placeholder="jhonedoe@example.com"
      //             type="email"
      //             name="name"
      //             value={this.state.email}
      //             onChange={(e) => this.setState({ email: e.target.value })}
      //           />
      //         </label>
      //         <label>
      //           <h4>Password:</h4>
      //           <input
      //             placeholder="Password"
      //             type="password"
      //             name="name"
      //             value={this.state.password}
      //             onChange={(e) => this.setState({ password: e.target.value })}
      //           />
      //         </label>
      //         <label>
      //           <h4>Confirm Password:</h4>
      //           <input
      //             placeholder="Password"
      //             type="password"
      //             name="name"
      //             value={this.state.confirmPassword}
      //             onChange={(e) =>
      //               this.setState({ confirmPassword: e.target.value })
      //             }
      //           />
      //         </label>

      //         <button className="primary-button" onClick={this.handleSignUp}>
      //           Register
      //         </button>
      //         <span>
      //           Already have an account ?{" "}
      //           <button
      //             className="link-button"
      //             onClick={() =>
      //               this.setState((prevState) => ({
      //                 userExist: !prevState.check,
      //               }))
      //             }
      //           >
      //             SignUp
      //           </button>
      //         </span>
      //       </>
      //     )}
      //   </div>
      // </div>
   
      <div sty>
          <Header ></Header>
          <br></br>
          <br></br>
         <br></br>
        
         <img
                width={200}
                src={require("./logo_vero_hive.png")}
                alt="MegaHoot"
              />
              <br></br>
              <div style={{display:'flex',justifyContent:'center'}}> <div style={{maxWidth:'80vw' }}>   <h6 style={{boxSizing:"border-box",fontFamily:'&quot;Open Sans&quot;,sans-serif',lineHeight:'1.4',margin:'0px 0px 1.5rem',paddingTop:'0px',
          fontWeight:'bold',fontSize:'25px',color:'#800080',textAlign:'center'}}>
        MegaHoot Mobile App
         </h6>
         <br></br>
     
         <h6 style={{boxSizing:"border-box",fontFamily:'&quot;Open Sans&quot;,sans-serif',lineHeight:'1.4',margin:'0px 0px 1.5rem',paddingTop:'0px',
         fontStyle:'italic',fontSize:'25px',color:'#800080',textAlign:'center'}}>
           Freedom To Roam
         </h6>
         <br></br>
          <h6 style={{boxSizing:"border-box",fontFamily:'&quot;Open Sans&quot;,sans-serif',lineHeight:'1.4',margin:'0px 0px 1.5rem',paddingTop:'0px',
          fontWeight:'bold',fontSize:'25px',color:'#800080',textAlign:'center'}}>
            Enhanced End to End Encryption to keep your communications private and secure
         </h6>
     <br></br>
   
     <video src={mega} id="video" controls loop="true" width="50%" autoPlay={true}  style={{borderRadius:'10px',minWidth:"300px"}} ></video>
    
     <br>
     </br>
     <br></br>
     {/* <p style={{maxWidth:'80vw',minWidth:'300px',lineHeight:'30px',fontSize:'20px'}}>
     Slated to be released during the third quarter of 2021, Members will be able to easily connect with their friends, staff, board or team on a peer to peer basis via a secure and private web, desktop and mobile application called "The MegaHoot App". With end to end encryption and other proprietary security measures there is no concern with who is listening in on your private communication. With the inclusion of ephemeral text messaging, your messages can have a timed expiration and since all communication is PRIVATE , MegaHoot will NEVER see or keep that data nor keep a backup of that data as to ensure the privacy of our community. 
     </p> */}
          <h6 style={{boxSizing:"border-box",fontFamily:'&quot;Open Sans&quot;,sans-serif',lineHeight:'1.4',margin:'0px 0px 1.5rem',paddingTop:'0px',
          fontWeight:'300',fontSize:'18px',color:'rgb(0,0,0)',textAlign:'justify'}}>
             Slated to be released during the third quarter of 2021, Members will be able to easily connect with their friends, staff, board or team on a peer to peer basis via a secure and private web, desktop and mobile application called "The MegaHoot App". With end to end encryption and other proprietary security measures there is no concern with who is listening in on your private communication. With the inclusion of ephemeral text messaging, your messages can have a timed expiration and since all communication is PRIVATE , MegaHoot will NEVER see or keep that data nor keep a backup of that data as to ensure the privacy of our community.          </h6>
     
            <h6 style={{boxSizing:"border-box",fontFamily:'&quot;Open Sans&quot;,sans-serif',lineHeight:'1.4',margin:'0px 0px 1.5rem',paddingTop:'0px',
          fontWeight:'300',fontSize:'18px',color:'rgb(0,0,0)',textAlign:'justify'}}>
          The MegaHoot App will also allow members to use the mobile application to join a VeroHive meeting when invited, whatever the Channel may be, they will not be able to host a VeroHive meeting via the MegaHoot App but they will be able to have in messenger video and audio calls. Members will also be able to access many other MegaHoot platforms such as MegaHoot Soapbox, Fortis Auction Blockmarket and the MegaHoot Vault.

</h6>
           <img
                width="60%"
                style={{borderRadius:'10px',minWidth:"300px"}}
                src={require("./b1.jpg")}
                alt="MegaHoot Messenger"
              />
            <h6 style={{boxSizing:"border-box",fontFamily:'&quot;Open Sans&quot;,sans-serif',lineHeight:'1.4',margin:'0px 0px 1.5rem',paddingTop:'0px',
          fontWeight:'300',fontSize:'18px',color:'rgb(0,0,0)',textAlign:'justify'}}>
          In the future the MegaHoot App will allow members to access DocuMega and other MegaHoot platforms all in one single mobile application.</h6>
     
           {/* <h6 style={{boxSizing:"border-box",fontFamily:'&quot;Open Sans&quot;,sans-serif',lineHeight:'1.4',margin:'0px 0px 1.5rem',paddingTop:'0px',
          fontWeight:'300',fontSize:'18px',color:'rgb(0,0,0)',textAlign:'justify'}}>
          Other features that are in development and will be added include stickers, emoticons, video filters, AR, XMG digital wallet access and much more.</h6> */}
     </div>
      </div>
      <div
    >
          {/* <img
                width={400}
                style={{minWidth:"300px",maxWidth:'80vw'}}
                src={require("./b2.jpg")}
                alt="MegaHoot Messenger"
              />
               <img
                width={400}
                style={{minWidth:"300px",maxWidth:'80vw'}}
                src={require("./b3.jpg")}
                alt="MegaHoot Messenger"
              />
              <br></br> */}
              <br></br>
      <div className="footer">
   
    
      <h4>Copyright MegaHoot Technologies, Inc All Rights Reserved</h4>
      </div>
     
    </div>
             
      </div>
   
   );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user, isLoggedIn) =>
    dispatch(setCurrentUser(user, isLoggedIn)),
});

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
  isLoggedIn: user.isLoggedIn,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomePage));
