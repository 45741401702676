import React, { Component } from 'react'
import Navbar from 'react-bootstrap/lib/Navbar'
import jwt_decode from 'jwt-decode'
import { setCurrentUser } from '../redux/user/user.actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
/**
 *
 * Renders top navbar and shows the current signed in user.
 */
class NavBar extends Component {
  state = {
    users: null,
  }
  componentDidMount = () => {
    const user = localStorage.getItem('token')
    const currentUser = jwt_decode(user)
    this.setState({ users: currentUser.result })
  }

  handleLogout = () => {
    const { setCurrentUser } = this.props
    setCurrentUser(null, false)
    localStorage.removeItem('token')
    this.props.history.push('/')
  }

  render() {
    return (
      <Navbar inverse>
        <Navbar.Header>
          <Navbar.Brand>
            <button
              style={{
                width: 'auto ',
                color: '#fff',
              }}
              className="danger-button"
              onClick={() => this.handleLogout()}
            >
              LogOut
            </button>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Navbar.Text pullRight>
            Signed in as:&nbsp;
            <span className="signed-in-user">
              {this.state.users ? this.state.users.firstName : null}
            </span>
          </Navbar.Text>
          <Navbar.Text pullRight>
            VeroKey :&nbsp;
            <span className="signed-in-user">
              {this.state.users ? this.state.users.privateKey : null}
            </span>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user, isLoggedIn) =>
    dispatch(setCurrentUser(user, isLoggedIn)),
})

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
  isLoggedIn: user.isLoggedIn,
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavBar))
