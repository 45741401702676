import React, { Component } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "react-chat-elements/dist/main.css";
import "./index.css";
import "react-notifications/lib/notifications.css";
import DashBoard from "./components/DashBoard.js";
import HomePage from "./components/HomePage.js";
import "./components/Header.js";
import "./components/Header.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/dashboard" component={DashBoard} />
        </Switch>
      </div>
    );
  }
}

export default App;
